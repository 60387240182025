import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  memo,
} from "react";
import {
  fetchCategories,
  fetchBookSummaries,
  fetchBookComments,
  getReadingProgress,
  getFinishedBooks,
  getBookFromBookmarks,
  getUserDocument,
  auth,
  DarkerType,
} from "../../utils/firebase/firebase.utils";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";

import {
  BookSummaryType,
  CategoryType,
  CommentType,
} from "../../routes/books/books.route";

// This needs to be changed entirely, I mean, I should make it fetch in pagination from Firebase!
// When? soon, by all means, when I get settled!
// Third day, I am just applying for jobs and getting settled, at least trying!
// Fourth day without implementing or changing anything, still finding my way!
// Fifth day, I feel lost, but I am trying to figure it out!
// Sixth day, almost there
// Seventh day, trying ..
// Eighth day, almost there ..
// Ninth day, almost there
// Tenth day, almost getting there!
// Eleventh day, almost here..
// Twelfth day, Finally! and Thank God
// Thirteenth day, Procrastinated this day ):
// Felt dismotivated, but I will get better soon!
// Anytime soon, promise
// Today feeling low energy, I don't know why
// I will change the world, I will make great stuff one day!
// Yes I will

// Today I am going to Riyadh and will be a good start, God willing!
// I am in Riyadh today and I am looking for a job for today

type BooksContextType = {
  categories: CategoryType[];
  bookSummaries: BookSummaryType[];
  loading: boolean;
  fetchBookComments: (
    categoryId: string,
    bookId: string
  ) => Promise<{ comments: CommentType[]; averageRating: number }>;
  getUserDocument: (userId: string) => Promise<DarkerType | null>;
  getBookFromBookmarks: (
    userId: string,
    bookId: string
  ) => Promise<DocumentData | null>;
  getReadingProgress: (userId: string, bookId: string) => Promise<number>;
  getFinishedBooks: (
    userId: string,
    bookId: string
  ) => Promise<DocumentData | undefined>;
};

const BooksContext = createContext<BooksContextType | undefined>(undefined);

export const BooksProvider = memo(({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [bookSummaries, setBookSummaries] = useState<BookSummaryType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot | null>(null);
  const [loading, setLoading] = useState(true);

  const loadCategoriesAndBookSummaries = useCallback(async () => {
    try {
      const fetchedCategories = await fetchCategories();
      const formattedCategories = fetchedCategories.map((category) => ({
        id: category.id,
        title: category.id,
        route: `/app/category/${category.id}`,
      }));
      setCategories(formattedCategories);

      const allBooks = await Promise.all(
        fetchedCategories.map(async (category) => {
          const bookSummaries = await fetchBookSummaries(category.id);
          setLastVisible(lastVisible);

          return bookSummaries.map((summary) => ({
            ...summary,
            categoryRoute: category.id,
          }));
        })
      );

      setBookSummaries(allBooks.flat());
    } catch (error) {
      console.error("Failed to load categories and book summaries:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadCategoriesAndBookSummaries();
  }, [loadCategoriesAndBookSummaries]);

  const fetchComments = useCallback(
    async (categoryId: string, bookId: string) => {
      const { comments, averageRating } = await fetchBookComments(
        categoryId,
        bookId
      );
      return { comments, averageRating };
    },
    []
  );

  const fetchUserDocument = async (userId: string) => {
    return await getUserDocument(userId);
  };

  const getBookmarks = useCallback(async (userId: string, bookId: string) => {
    const bookmarked = await getBookFromBookmarks(userId, bookId);
    return bookmarked;
  }, []);

  const getReadingProgressHandler = useCallback(
    async (userId: string, bookId: string) => {
      return await getReadingProgress(userId, bookId);
    },
    []
  );

  const getFinishedBooksHandler = useCallback(
    async (userId: string, bookId: string) => {
      return await getFinishedBooks(userId, bookId);
    },
    []
  );

  return (
    <BooksContext.Provider
      value={{
        categories,
        bookSummaries,
        fetchBookComments: fetchComments,
        getUserDocument: fetchUserDocument,
        getBookFromBookmarks: getBookmarks,
        getReadingProgress: getReadingProgressHandler,
        getFinishedBooks: getFinishedBooksHandler,
        loading,
      }}
    >
      {children}
    </BooksContext.Provider>
  );
});

export const useBooks = () => {
  const context = useContext(BooksContext);
  if (!context) {
    throw new Error("useBooks must be used within a BooksProvider");
  }
  return context;
};
