import { initializeApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  NextOrObserver,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateProfile,
  User,
  UserCredential,
} from "firebase/auth";
import {
  getDoc,
  addDoc,
  getFirestore,
  QueryDocumentSnapshot,
  setDoc,
  doc,
  collection,
  getDocs,
  query,
  orderBy,
  where,
  updateDoc,
  deleteDoc,
  limit,
  DocumentSnapshot,
  startAfter,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";

import {
  BookSummaryType,
  CategoryType,
  CommentType,
} from "../../routes/books/books.route";

import {
  ref,
  getDownloadURL,
  getStorage,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";
import { ThoughtReplyType } from "../../routes/thought-preview/thought-preview.route";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
const vertexAI = getVertexAI(app);

export const model = getGenerativeModel(vertexAI, {
  model: "gemini-1.5-flash",
});

export const run = async (
  userMessage: string,
  context: string,
  chatHistory: string
) => {
  try {
    const input = `${context}\n${chatHistory}\nUser: ${userMessage}\nAssistant:`;
    const result = await model.generateContent(input);
    return result.response.text().trim();
  } catch (error) {
    console.error("Error occurred: ", error);
    return "Sorry, something went wrong. Try again in a minute.";
  }
};

// <-------------------------------------------------------> //
// <-------------------------------------------------------> //
// <-------------------------------------------------------> //

// Everything about Users and Auth:

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export type AdditionalInformation = {
  displayName?: string;
  userNumber?: number;
};

export type UserData = {
  createdAt: Date;
  displayName: string;
  userNumber: number;
  email: string;
  photoURL?: string;
  uid: string;
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string,
  displayName: string
) => {
  if (!email || !password) return;

  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;

  if (displayName) {
    await updateProfile(user, { displayName });
  }

  return userCredential;
};

export const sendVerificationEmail = async (user: User) => {
  if (user) {
    await sendEmailVerification(user);
  }
};

export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation: AdditionalInformation = {} as AdditionalInformation
): Promise<void | QueryDocumentSnapshot<UserData>> => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  const { displayName, email } = userAuth;
  const photoURL = userAuth.photoURL;
  const createdAt = new Date();

  try {
    if (!userSnapshot.exists()) {
      await setDoc(userDocRef, {
        displayName: displayName || "Darker",
        email,
        createdAt,
        photoURL,
        ...additionalInformation,
      });
    } else {
      // If user document exists, update it with additionalInformation
      await setDoc(
        userDocRef,
        {
          displayName: displayName || "Darker",
          email,
          photoURL,
          ...additionalInformation,
        },
        { merge: true }
      );
    }
  } catch (error) {
    console.log("Error creating or updating the user document:", error);
  }
  return userSnapshot as QueryDocumentSnapshot<UserData>;
};

export const getUserDocument = async (
  userId: string
): Promise<DarkerType | null> => {
  const userDocRef = doc(db, "users", userId);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    return userDoc.data() as DarkerType;
  } else {
    return null;
  }
};

export type DarkerType = {
  id: string;
  displayName: string;
  pronouns: string;
  bio: string;
  photoURL: string;
  location: string;
  website: string;
  createdAt: Date;
  userNumber: string;

  subscription?: {
    id: string;
    status: string;
    plan: string;
    renewalDate: string;
    cardBrand: string;
    cardLastFour: string;
  };
};

export const fetchAllUsers = async () => {
  const usersRef = collection(db, "users");
  const userSnapshot = await getDocs(usersRef);
  const allUsers = userSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return allUsers;
};

export const fetchUserByUserNumber = async (
  userNumber: number
): Promise<DarkerType> => {
  const userRef = collection(db, "users");
  const userQuery = query(
    userRef,
    where("userNumber", "==", userNumber),
    limit(1)
  );
  const userSnapshot = await getDocs(userQuery);

  if (!userSnapshot.empty) {
    return {
      id: userSnapshot.docs[0].id,
      ...userSnapshot.docs[0].data(),
    } as DarkerType;
  } else {
    throw new Error("Darker not found");
  }
};

export const signInWithGooglePopup = async (): Promise<UserCredential> => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Check if user is verified and handle Firestore document
    if (user.emailVerified) {
      const userDocRef = doc(db, "users", user.uid);
      const userSnapshot = await getDoc(userDocRef);

      if (!userSnapshot.exists()) {
        const { displayName, email } = user;
        if (!email) {
          console.error("User email is not available.");
          return result;
        }

        const createdAt = new Date();
        await setDoc(userDocRef, {
          displayName: displayName || "Darker",
          email,
          createdAt,
        });
      }
    }

    return result;
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw new Error("Sign in with Google failed.");
  }
};

export const checkIfUserNumberExists = async (
  userNumber: number
): Promise<boolean> => {
  try {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("userNumber", "==", userNumber));
    const snapshot = await getDocs(q);

    return !snapshot.empty;
  } catch (error) {
    console.error("Error checking user number:", error);
    throw error;
  }
};

export const uploadImage = async (file: File): Promise<string> => {
  const storageRef = ref(storage, `user_avatars/${uuidv4()}`);
  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

export const updateUserProfile = async (
  userId: string,
  updates: {
    displayName?: string;
    pronouns?: string;
    photoURL?: string;
    bio?: string;
    location?: string;
    website?: string;
  }
) => {
  const user = auth.currentUser;

  if (!user) {
    console.error("No authenticated user.");
    return;
  }

  const { displayName, pronouns, photoURL, bio, location, website } = updates;

  try {
    if (photoURL) {
      if (user.photoURL && photoURL !== user.photoURL) {
        await deleteOldPhoto(user.photoURL);
      }

      await updateProfile(user, {
        displayName: displayName || user.displayName,
        photoURL,
      });
    } else {
      await updateProfile(user, {
        displayName: displayName || user.displayName,
      });
    }

    // Step 2: Prepare updates for Firestore
    const firestoreUpdates: any = {};
    if (displayName) firestoreUpdates.displayName = displayName;
    if (pronouns) firestoreUpdates.pronouns = pronouns;
    if (photoURL) firestoreUpdates.photoURL = photoURL;
    if (bio !== undefined) firestoreUpdates.bio = bio;
    if (location !== undefined) firestoreUpdates.location = location;
    if (website !== undefined) firestoreUpdates.website = website;

    // Step 3: Update Firestore document if there are updates
    if (Object.keys(firestoreUpdates).length > 0) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, firestoreUpdates);
    }

    console.log("User profile updated successfully.");
  } catch (error) {
    console.error("Error updating user profile:", error);
  }
};

export const deleteOldPhoto = async (oldPhotoURL: string) => {
  try {
    const oldPhotoRef = ref(storage, oldPhotoURL);
    await deleteObject(oldPhotoRef);
    console.log("Old photo deleted successfully");
  } catch (error) {
    console.error("Error deleting old photo: ", error);
  }
};

export const signInAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback: NextOrObserver<User>) =>
  onAuthStateChanged(auth, callback);

export const getCurrentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

// <-------------------------------------------------------> //
// <-------------------------------------------------------> //
// <-------------------------------------------------------> //

// Everything about categories and both book-darker summaries and related stuff.

export const fetchCategories = async (): Promise<CategoryType[]> => {
  try {
    const categoriesRef = collection(db, "categories");
    const querySnapshot = await getDocs(categoriesRef);

    return querySnapshot.docs.map(
      (docSnapshot) =>
        ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        } as CategoryType)
    );
  } catch (error) {
    console.error("Error fetching categories: ", error);
    throw error;
  }
};

export const fetchBookSummaries = async (
  categoryId: string
): Promise<BookSummaryType[]> => {
  try {
    const bookSummariesRef = collection(
      db,
      "categories",
      categoryId,
      "bookSummaries"
    );
    const darkerSummariesRef = collection(
      db,
      "categories",
      categoryId,
      "darkerSummaries"
    );

    // Fetch both sub-collections in parallel
    const [bookSummariesSnapshot, darkerSummariesSnapshot] = await Promise.all([
      getDocs(bookSummariesRef),
      getDocs(darkerSummariesRef),
    ]);

    const combinedSummaries: BookSummaryType[] = [
      ...bookSummariesSnapshot.docs.map((doc) => {
        const data = doc.data() as BookSummaryType;
        return {
          ...data,
          id: doc.id,
        };
      }),
      ...darkerSummariesSnapshot.docs.map((doc) => {
        const data = doc.data() as BookSummaryType;
        return {
          ...data,
          id: doc.id,
        };
      }),
    ];

    return combinedSummaries;
  } catch (error) {
    console.error("Error fetching book summaries: ", error);
    throw error;
  }
};

export const addBookSummary = async (
  categoryId: string,
  bookImage: string,
  bookSummary: {
    title: string;
    author: string;
    aboutBook: string;
    bookReference: string;
    enPages: {
      header: string;
      subHeader?: string;
      content1: string;
      subHeader2?: string;
      content2?: string;
      subHeader3?: string;
      content3?: string;
      subHeader4?: string;
      content4?: string;
      order: number;
    }[];
    arPages: {
      header: string;
      subHeader?: string;
      content1: string;
      subHeader2?: string;
      content2?: string;
      subHeader3?: string;
      content3?: string;
      subHeader4?: string;
      content4?: string;
      order: number;
    }[];
    tags: string[];
  }
) => {
  try {
    const user = auth.currentUser;
    const userId = auth.currentUser?.uid;
    if (!user) {
      throw new Error("No authenticated user");
    }

    const addedBy = auth.currentUser?.email;
    const addedAt = new Date();

    // Get device info
    const deviceInfo = navigator.userAgent;

    const categoryRef = doc(db, "categories", categoryId);
    const bookSummariesRef = collection(categoryRef, "bookSummaries");

    // Trim string fields in bookSummary
    const trimmedBookSummary = {
      title: bookSummary.title.trim(),
      author: bookSummary.author.trim(),
      aboutBook: bookSummary.aboutBook.trim(),
      bookReference: bookSummary.bookReference.trim(),
      enPages: bookSummary.enPages.map((page) => ({
        header: page.header.trim(),
        subHeader: page.subHeader?.trim(),
        content1: page.content1.trim(),
        subHeader2: page.subHeader2?.trim(),
        content2: page.content2?.trim(),
        subHeader3: page.subHeader3?.trim(),
        content3: page.content3?.trim(),
        subHeader4: page.subHeader4?.trim(),
        content4: page.content4?.trim(),
        order: page.order,
      })),
      arPages: bookSummary.arPages.map((page) => ({
        header: page.header.trim(),
        subHeader: page.subHeader?.trim(),
        content1: page.content1.trim(),
        subHeader2: page.subHeader2?.trim(),
        content2: page.content2?.trim(),
        subHeader3: page.subHeader3?.trim(),
        content3: page.content3?.trim(),
        subHeader4: page.subHeader4?.trim(),
        content4: page.content4?.trim(),
        order: page.order,
      })),
    };

    await addDoc(bookSummariesRef, {
      title: trimmedBookSummary.title,
      author: trimmedBookSummary.author,
      aboutBook: trimmedBookSummary.aboutBook,
      bookReference: trimmedBookSummary.bookReference,
      enPages: trimmedBookSummary.enPages,
      arPages: trimmedBookSummary.arPages,
      tags: bookSummary.tags,
      bookImage,
      addedBy,
      addedAt,
      deviceInfo,
      userId,
    });
  } catch (error) {
    console.error("Error adding book summary: ", error);
    throw error;
  }
};

export const deleteBookSummaryFromFirestore = async (
  categoryId: string,
  bookId: string,
  imageURL: string
) => {
  const categoryRef = doc(db, "categories", categoryId);
  const bookSummaryRef = doc(
    collection(categoryRef, "darkerSummaries"),
    bookId
  );

  await deleteDoc(bookSummaryRef);

  const imageRef = ref(storage, imageURL);
  await deleteObject(imageRef);
};

export const uploadBookImageToStorage = async (imageFile: File) => {
  const storageRef = ref(storage, `darker_summaries_images/${uuidv4()}`);
  await uploadBytes(storageRef, imageFile);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

// Add a book to the user's bookmarks
export const addBookToBookmarks = async (userId: string, bookId: string) => {
  try {
    const bookmarkRef = doc(db, "users", userId, "bookmarks", bookId);
    await setDoc(bookmarkRef, { userId, bookId }, { merge: true });
  } catch (error) {
    console.error("Error adding book to bookmarks: ", error);
  }
};

export const getBookFromBookmarks = async (userId: string, bookId: string) => {
  try {
    const bookmarkRef = doc(db, "users", userId, "bookmarks", bookId);
    const bookmarkDoc = await getDoc(bookmarkRef);

    if (bookmarkDoc.exists()) {
      return bookmarkDoc.data();
    } else return null;
  } catch (error) {
    console.error("Error getting bookmarked book: ", error);
    return null;
  }
};

export const removeBookFromBookmarks = async (
  userId: string,
  bookId: string
) => {
  try {
    const bookmarkRef = doc(db, "users", userId, "bookmarks", bookId);
    await deleteDoc(bookmarkRef);
  } catch (error) {
    console.error("Error removing book from bookmarks: ", error);
  }
};

export const addCommentBook = async (
  bookId: string,
  categoryId: string,
  userId: string,
  commentId: string,
  rating: number,
  comment?: string
): Promise<void> => {
  try {
    const commentRef = doc(
      db,
      "categories",
      categoryId,
      "bookSummaries",
      bookId,
      "bookComments",
      commentId
    );
    const date = new Date();

    const newComment = {
      userId,
      rating,
      comment,
      date,
    };

    // Set the new comment data in the specified comment document
    await setDoc(commentRef, newComment, { merge: true });
  } catch (error) {
    console.error("Error adding a comment", error);
    throw error;
  }
};

export const fetchBookComments = async (
  categoryId: string,
  bookId: string
): Promise<{ comments: CommentType[]; averageRating: number }> => {
  const commentsRef = collection(
    db,
    "categories",
    categoryId,
    "bookSummaries",
    bookId,
    "bookComments"
  );

  const querySnapshot = await getDocs(commentsRef);

  const comments: CommentType[] = [];
  let totalRating = 0;

  querySnapshot.forEach((doc) => {
    const commentData = doc.data() as CommentType;
    comments.push(commentData);
    totalRating += commentData.rating;
  });

  const averageRating = comments.length > 0 ? totalRating / comments.length : 0;

  return { comments, averageRating };
};

export const saveReadingProgress = async (
  userId: string,
  bookId: string,
  categoryId: string,
  pageNumber: number
) => {
  try {
    const readingProgressRef = doc(
      db,
      "users",
      userId,
      "readingProgress",
      bookId
    );
    await setDoc(
      readingProgressRef,
      {
        userId,
        bookId,
        categoryId,
        pageNumber,
      },
      { merge: true }
    );
  } catch (error) {
    console.error("Error saving reading progress: ", error);
  }
};

export const getReadingProgress = async (userId: string, bookId: string) => {
  try {
    const readingProgressRef = doc(
      db,
      "users",
      userId,
      "readingProgress",
      bookId
    );
    const readingProgressDoc = await getDoc(readingProgressRef);

    if (readingProgressDoc.exists()) {
      const data = readingProgressDoc.data();
      return data?.pageNumber || 1;
    } else {
      return 1;
    }
  } catch (error) {
    console.error("Error retrieving reading progress: ", error);
    return 1;
  }
};

export const deleteReadingProgress = async (userId: string, bookId: string) => {
  try {
    const readingProgressRef = doc(
      db,
      "users",
      userId,
      "readingProgress",
      bookId
    );
    await deleteDoc(readingProgressRef);

    const finishedBooksRef = doc(db, "users", userId, "finishedBooks", bookId);

    await setDoc(finishedBooksRef, {
      userId,
      bookId,
    });
  } catch (error) {
    console.error("Error deleting reading progress: ", error);
  }
};

export const getFinishedBooks = async (userId: string, bookId: string) => {
  try {
    const finishedBooksRef = doc(db, "users", userId, "finishedBooks", bookId);
    const finishedBookDoc = await getDoc(finishedBooksRef);

    if (finishedBookDoc.exists()) {
      const data = finishedBookDoc.data();
      return data;
    } else return;
  } catch (error) {
    console.error("Error getting the finished book");
  }
};

// <-------------------------------------------------------> //
// <-------------------------------------------------------> //
// <-------------------------------------------------------> //

// Everything about Thoughts and related stuff.

export type ThoughtsData = {
  id: string;
  content: string;
  date: string;
  likes: number;
  likedBy: string[];
  displayName: string;
  photoURL: string;
  userNumber: string;
  currentUserId: string;
  bio: string;
  createdAt: string;
};

export const addThought = async (userId: string, content: string) => {
  try {
    const currentUserId = auth.currentUser?.uid;
    const addedBy = auth.currentUser?.email;
    const date = new Date();
    const deviceInfo = navigator.userAgent;

    const thoughtsRef = collection(db, "thoughts");

    await addDoc(thoughtsRef, {
      content,
      currentUserId,
      addedBy,
      date,
      deviceInfo,
    });
  } catch (error) {
    console.error("Error adding a thought: ", error);
    throw error;
  }
};

export const fetchThoughtsWithUserData = async () => {
  try {
    const thoughtsRef = collection(db, "thoughts");
    const q = query(thoughtsRef, orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);

    const thoughtsWithUserDetails = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const thoughtData = docSnapshot.data() as ThoughtsData;

        // Fetch the user doc based on the currentUserId
        const userRef = doc(db, "users", thoughtData.currentUserId);
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data();

        return {
          ...thoughtData,
          id: docSnapshot.id,
          userNumber: userData?.userNumber,
          displayName: userData?.displayName,
          photoURL: userData?.photoURL,
          bio: userData?.bio,
          createdAt: userData?.createdAt,
        };
      })
    );

    return thoughtsWithUserDetails;
  } catch (error) {
    console.error("Error fetching thoughts with user data: ", error);
    throw error;
  }
};

export const fetchThoughtsByUserId = async (
  userId: string
): Promise<ThoughtsData[]> => {
  const thoughtsRef = collection(db, "thoughts");
  const q = query(thoughtsRef, where("currentUserId", "==", userId));
  const querySnapshot = await getDocs(q);

  const thoughts: ThoughtsData[] = querySnapshot.docs.map((doc) => {
    const thoughtData = doc.data() as Omit<ThoughtsData, "id">; // Exclude 'id'

    return {
      id: doc.id, // Add the document ID separately
      ...thoughtData, // Include all other fields from the document
    };
  });

  return thoughts; // This returns an array of ThoughtsData[]
};

export const deleteThoughtFromFirestore = async (thoughtId: string) => {
  try {
    const thoughtRef = doc(db, "thoughts", thoughtId);
    const replyRef = collection(thoughtRef, "replies");

    const replySnapshot = await getDocs(query(replyRef));

    const deleteReplyPromises = replySnapshot.docs.map((replyDoc) =>
      deleteDoc(replyDoc.ref)
    );
    await Promise.all(deleteReplyPromises);

    await deleteDoc(thoughtRef);
  } catch (error) {
    console.error("Error deleting the document and subcollections: ", error);
  }
};

export const deleteThoughtReplyFromFirestore = async (
  thoughtId: string,
  replyId: string
) => {
  try {
    const replyRef = doc(db, "thoughts", thoughtId, "replies", replyId);

    await deleteDoc(replyRef);
  } catch (error) {
    console.error("Error deleting the document and subcollections: ", error);
  }
};

export const addLikeToThought = async (thoughtId: string, userId: string) => {
  try {
    const likesRef = doc(db, "thoughts", thoughtId, "likes", userId);

    // Check if the like already exists
    const likesDoc = await getDoc(likesRef);
    if (!likesDoc.exists()) {
      await setDoc(
        likesRef,
        { thoughtId, userId, createdAt: new Date() },
        { merge: true }
      );
    }
  } catch (error) {
    console.error("Error adding like: ", error);
  }
};

export const getLikesFromThought = async (
  thoughtId: string,
  userId: string
) => {
  try {
    const likesRef = collection(db, "thoughts", thoughtId, "likes");
    const likesDoc = await getDocs(likesRef);

    const likesCount = likesDoc.size;

    const userLiked = (await getDoc(doc(likesRef, userId))).exists();
    return {
      count: likesCount,
      userLiked: userLiked,
    };
  } catch (error) {
    console.error("Error getting likes from thought: ", error);
    return null;
  }
};

export const removeLikeFromThought = async (
  thoughtId: string,
  userId: string
) => {
  try {
    const likesRef = doc(db, "thoughts", thoughtId, "likes", userId);

    // Check if the like exists before removing
    const likesDoc = await getDoc(likesRef);
    if (likesDoc.exists()) {
      await deleteDoc(likesRef);
    }
  } catch (error) {
    console.error("Error removing the like: ", error);
  }
};

export const addThoughtReply = async (
  thoughtId: string,
  userId: string,
  reply: string
) => {
  try {
    const currentUserId = auth.currentUser?.uid;
    const addedBy = auth.currentUser?.email;
    const date = new Date();
    const deviceInfo = navigator.userAgent;

    const thoughtRef = doc(db, "thoughts", thoughtId);
    const thoughtReplyRef = collection(thoughtRef, "replies");

    await addDoc(thoughtReplyRef, {
      reply,
      currentUserId,
      addedBy,
      date,
      deviceInfo,
    });
  } catch (error) {
    console.error("Error adding a reply: ", error);
  }
};

export const fetchThoughtsRepliesWithUserData = async (thoughtId: string) => {
  try {
    const thoughtReplyRef = collection(db, "thoughts", thoughtId, "replies");

    const q = query(thoughtReplyRef, orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);

    const repliesWithUserDetails = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const replyData = docSnapshot.data() as ThoughtReplyType;

        const userRef = doc(db, "users", replyData.currentUserId);
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data();

        return {
          ...replyData,
          id: docSnapshot.id,
          userNumber: userData?.userNumber,
          displayName: userData?.displayName,
          photoURL: userData?.photoURL,
        };
      })
    );

    return repliesWithUserDetails;
  } catch (error) {
    console.error("Error fetching replies with user data: ", error);
    throw error;
  }
};

// -------------------------------
// Support
// -------------------------------

export const contactSupport = async (message: string, email?: string) => {
  const senderEmail = auth.currentUser?.email;
  const date = new Date();
  const deviceInfo = navigator.userAgent;

  try {
    const supportRef = collection(db, "support");

    await addDoc(supportRef, {
      message,
      email,
      senderEmail,
      date,
      deviceInfo,
    });
  } catch (error) {
    console.error("Error sending it: ", error);
  }
};
