import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ModalOverlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 30px;
  color: ${({ theme }) => theme.text};
  border-radius: 15px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transform: scale(0.9); 
  animation: ${fadeIn} 0.3s ease-in-out forwards; 
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px; 
  cursor: pointer;
  transition: color 0.3s;
  
  &:hover {
    color: red; 
  }
`;
