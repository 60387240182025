import { useEffect, useState } from "react";

import { ModalOverlay, ModalContent, CloseButton } from "./install-app.styles";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}

const isIOS = () => {
  const userAgent = window.navigator.userAgent;
  return /iPhone|iPad|iPod/.test(userAgent);
};

const InstallApp = () => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsVisible(true);
    };

    const isStandalone = (window.navigator as any).standalone;
    if (isIOS() && !isStandalone) {
      setIsIOSDevice(true);
      setIsVisible(true);
    }

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <ModalOverlay isVisible={isVisible}>
          <ModalContent>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
            {isIOSDevice ? (
              <p>
                Install Darkerverse on your phone for a better experience: Tap
                the share icon{" "}
                <span role="img" aria-label="share">
                  📤
                </span>{" "}
                and then "Add to Home Screen".
              </p>
            ) : (
              <div>
                <p>Install our app for a better experience!</p>
                <button onClick={handleInstallClick}>Install</button>
              </div>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default InstallApp;
