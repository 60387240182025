import React, { createContext, useState, useEffect, useContext } from "react";
import { ThemeProvider } from "styled-components";
import {
  Theme,
  lightTheme,
  darkTheme,
  purpleTheme,
  redTheme,
} from "../../utils/themes/themes";

type ThemeContextType = {
  selectedTheme: Theme;
  updateTheme: (theme: Theme, themeName: string) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedTheme, setSelectedTheme] = useState<Theme>(lightTheme);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    switch (savedTheme) {
      case "dark":
        setSelectedTheme(darkTheme);
        break;
      case "purple":
        setSelectedTheme(purpleTheme);
        break;
      case "red":
        setSelectedTheme(redTheme);
        break;
      default:
        setSelectedTheme(lightTheme);
    }
  }, []);

  const updateTheme = (theme: Theme, themeName: string) => {
    setSelectedTheme(theme);
    localStorage.setItem("theme", themeName);
  };

  return (
    <ThemeContext.Provider value={{ selectedTheme, updateTheme }}>
      <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
