import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

import GlobalStyles from "./GlobalStyles";

import { ThemeContextProvider } from "./contexts/themes/themes.context";
import { BooksProvider } from "./contexts/books/books.context";
import { ThoughtsProvider } from "./contexts/thoughts/thoughts.context";
import { UsersProvider } from "./contexts/users/users.context";

import InstallApp from "./components/install-app/install-app.component";

const Home = lazy(() => import("./routes/home/home.route"));
const About = lazy(() => import("./routes/about/about.route"));
const Pricing = lazy(() => import("./components/pricing/pricing.component"));
const PrivacyPolicy = lazy(
  () => import("./components/privacy-policy/privacy-policy.component")
);
const TermsAndConditions = lazy(
  () => import("./components/terms-conditions/terms-conditions.component")
);

const SignUpForm = lazy(
  () => import("./routes/sign-up-form/sign-up-form.route")
);
const LoginForm = lazy(() => import("./routes/login-form/login-form.route"));
const Spinner = lazy(() => import("./components/spinner/spinner.component"));
const AppView = lazy(() => import("./routes/app/app-view.route"));
const ForgotPassword = lazy(
  () => import("./routes/forgot-password/forgot-password.route")
);
const ProtectedRoute = lazy(
  () => import("./routes/protected-route/protected-route")
);
const PreventLoggedInRoute = lazy(
  () => import("./routes/prevent-logged-in-route/prevent-logged-in-route")
);
const CategoryBooks = lazy(
  () => import("./routes/category-books/category-books.route")
);
const Books = lazy(() => import("./routes/books/books.route"));
const BookPreview = lazy(
  () => import("./routes/book-preview/book-preview.route")
);
const Book = lazy(() => import("./routes/book/book.route"));
const Discover = lazy(() => import("./routes/discover/discover.route"));
const TagBooks = lazy(() => import("./routes/tag-books/tag-books.route"));
const AddBookSummary = lazy(
  () => import("./components/add-darker-summary/add-darker-summary.component")
);
const Thoughts = lazy(() => import("./routes/thought/thought.route"));
const ThoughtsPreview = lazy(
  () => import("./routes/thought-preview/thought-preview.route")
);
const MoejrAI = lazy(() => import("./routes/moejr-ai/moejr-ai.route"));
const Search = lazy(() => import("./routes/search/search.route"));
const Account = lazy(() => import("./routes/account/account.route"));
const MyLibrary = lazy(() => import("./routes/my-library/my-library.route"));
const Billing = lazy(() => import("./components/billing/billing.component"));
const Support = lazy(() => import("./components/support/support.component"));

const App = () => {
  return (
    <ThemeContextProvider>
      <Suspense fallback={<Spinner />}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <Home />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="about"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <About />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="pricing"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <Pricing />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <PrivacyPolicy />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="terms-conditions"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <TermsAndConditions />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="sign-up"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <SignUpForm />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="login"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <LoginForm />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="forgot-password"
              element={
                <PreventLoggedInRoute redirectTo="/app">
                  <ForgotPassword />
                </PreventLoggedInRoute>
              }
            />
            <Route
              path="app"
              element={
                <ProtectedRoute>
                  <BooksProvider>
                    <ThoughtsProvider>
                      <UsersProvider>
                        <AppView />
                      </UsersProvider>
                    </ThoughtsProvider>
                  </BooksProvider>
                </ProtectedRoute>
              }
            >
              <Route path="home" element={<Discover />} />
              <Route path="billing" element={<Billing />} />
              <Route path="books" element={<Books />} />
              <Route path="add-book" element={<AddBookSummary />} />
              <Route path="thoughts" element={<Thoughts />} />
              <Route path="moejrAI" element={<MoejrAI />} />
              <Route path="my-library" element={<MyLibrary />} />
              <Route path="search" element={<Search />} />
              <Route path=":userNumber" element={<Account />} />
              <Route path="category/:categoryId" element={<CategoryBooks />} />
              <Route path="tag/:tagId" element={<TagBooks />} />
              <Route
                path=":categoryId/preview/:bookId"
                element={<BookPreview />}
              />
              <Route path=":categoryId/:bookId" element={<Book />} />
              <Route
                path="thoughts/:userNumber/:thoughtId"
                element={<ThoughtsPreview />}
              />
              <Route
                path="support"
                element={<Support />}
              />
            </Route>
          </Routes>
        </Router>
        <Analytics />
        <InstallApp />
      </Suspense>
    </ThemeContextProvider>
  );
};

export default App;
