import { Timestamp } from "firebase/firestore";

export const timeAgo = (date: Date) => {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervalDays = Math.floor(seconds / 86400);
  const intervalHours = Math.floor(seconds / 3600);
  const intervalMinutes = Math.floor(seconds / 60);

  if (intervalDays > 10) {
    // More than 10 days, return the full date
    return date.toDateString(); // Adjust format as needed
  } else if (intervalDays > 1) {
    return `${intervalDays}d`;
  } else if (intervalDays === 1) {
    return "1d";
  } else if (intervalHours > 1) {
    return `${intervalHours}h`;
  } else if (intervalHours === 1) {
    return "1h";
  } else if (intervalMinutes > 1) {
    return `${intervalMinutes}m`;
  } else if (intervalMinutes === 1) {
    return "1m";
  } else {
    return `${Math.floor(seconds)}s`;
  }
};

export const formatFirestoreDate = (date: any): Date => {
  if (date instanceof Timestamp) {
    return date.toDate(); // Convert Firestore Timestamp to JS Date
  }
  return new Date(date); // Handle other formats, if any
};

export const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};
