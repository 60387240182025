export type Theme = {
  background: string;
  innerBackground: string;
  text: string;
  cardBackground: string;
  buttonBackground: string;
  hover: string;
  border: string;
  darker: string;
  darkerBG: string;
  moejrAI: string;
  moejrAIBG: string;
  input: string;
  send: string;
};

export const lightTheme: Theme = {
  background: "#fafafa",
  innerBackground: "#fff",
  text: "#000",
  cardBackground: "rgba(255, 255, 255, 1)",
  buttonBackground: "#007bff",
  hover: "#eeeeeeb7",
  border: "#8d8d8db7",
  darker: "#fff",
  darkerBG: "#000",
  moejrAI: "#000",
  moejrAIBG: "#fff",
  input: "#fff",
  send: "#000",
};

export const darkTheme: Theme = {
  background: "#111111",
  innerBackground: "#000",
  text: "#E1D5E7",
  cardBackground: "#1e1e1e",
  buttonBackground: "#6200ea",
  hover: "#070707b7",
  border: "#444",
  darker: "#000",
  darkerBG: "#fff",
  moejrAI: "#fff",
  moejrAIBG: "#252525",
  input: "#000",
  send: "#fff",
};

export const purpleTheme: Theme = {
  background: "#45146D",
  innerBackground: "rgba(56, 32, 88, 0.5)",
  text: "#E1D5E7",
  cardBackground: "#5B1A82",
  buttonBackground: "#7A4B8A",
  hover: "#461365",
  border: "rgba(255, 255, 255, 0.3)",
  darker: "#fff",
  darkerBG: "#7116aa",
  moejrAI: "#fff",
  moejrAIBG: "#5B1A82",
  input: "#45146D",
  send: "#fff",
};

export const redTheme: Theme = {
  background: "#5e1212",
  innerBackground: "rgba(56, 32, 88, 0.5)",
  text: "#E1D5E7",
  cardBackground: "#6e1616",
  buttonBackground: "#4caf50",
  hover: "#651313",
  border: "rgba(255, 255, 255, 0.3)",
  darker: "#fff",
  darkerBG: "#a01313",
  moejrAI: "#fff",
  moejrAIBG: "#791414",
  input: "#5e1212",
  send: "#fff",
};
