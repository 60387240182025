import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  memo,
} from "react";

import {
  ThoughtsData,
  fetchThoughtsWithUserData,
  getLikesFromThought,
} from "../../utils/firebase/firebase.utils";

type LikesData = {
  count: number;
  userLiked: boolean;
};

type ThoughtsContextType = {
  thoughts: ThoughtsData[];
  fetchThoughts: () => Promise<void>;
  loading: boolean;
  getLikesFromThought: (
    thoughtId: string,
    userId: string
  ) => Promise<LikesData | null>;
};

type ThoughtsProviderProps = {
  children: ReactNode;
};

const ThoughtsContext = createContext<ThoughtsContextType | undefined>(
  undefined
);

export const ThoughtsProvider = memo(({ children }: ThoughtsProviderProps) => {
  const [thoughts, setThoughts] = useState<ThoughtsData[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchThoughts = useCallback(async () => {
    if (!loading) return;
    try {
      const fetchedThoughts = await fetchThoughtsWithUserData();
      setThoughts(fetchedThoughts);
    } catch (error) {
      console.error("Error fetching thoughts");
    } finally {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    fetchThoughts();
  }, [fetchThoughts]);

  const getLikes = useCallback(async (thoughtId: string, userId: string) => {
    const liked = await getLikesFromThought(thoughtId, userId);
    return liked;
  }, []);

  return (
    <ThoughtsContext.Provider
      value={{
        thoughts,
        fetchThoughts,
        loading,
        getLikesFromThought: getLikes,
      }}
    >
      {children}
    </ThoughtsContext.Provider>
  );
});

export const useThoughts = (): ThoughtsContextType => {
  const context = useContext(ThoughtsContext);
  if (!context) {
    throw new Error(
      "useThoughtsContext must be used within a ThoughtsProvider"
    );
  }
  return context;
};
