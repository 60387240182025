import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useMemo,
  memo,
} from "react";
import { useParams } from "react-router-dom";
import {
  fetchUserByUserNumber,
  fetchThoughtsByUserId,
  ThoughtsData,
} from "../../utils/firebase/firebase.utils";
import { Timestamp } from "firebase/firestore";
import { formatDate, formatFirestoreDate } from "../../utils/timeAgo/timeAgo";

type UsersContextType = {
  userData: {
    displayName: string;
    pronouns: string;
    bio: string;
    photoURL: string;
    location: string;
    website: string;
  };
  userId: string;
  joinedAt: string;
  thoughts: ThoughtsData[];
  loading: boolean;
  fetchAndSetData: (userNumber: number) => Promise<void>;
};

const UsersContext = createContext<UsersContextType | undefined>(undefined);

export const UsersProvider = memo(({ children }: { children: ReactNode }) => {
  const { userNumber } = useParams<{ userNumber: string }>();

  const [thoughts, setThoughts] = useState<ThoughtsData[]>([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    displayName: "",
    pronouns: "",
    bio: "",
    photoURL: "",
    location: "",
    website: "",
  });
  const [joinedAt, setJoinedAt] = useState("");
  const [userId, setUserId] = useState("");

  const fetchAndSetData = useCallback(async (userNumber: number) => {
    try {
      const userDoc = await fetchUserByUserNumber(Number(userNumber));
      if (userDoc) {
        setUserData({
          displayName: userDoc.displayName || "",
          pronouns: userDoc.pronouns || "",
          bio: userDoc.bio || "",
          photoURL: userDoc.photoURL || "",
          location: userDoc.location || "",
          website: userDoc.website || "",
        });
        setUserId(userDoc.id);

        const fetchedThoughts = await fetchThoughtsByUserId(userDoc.id);
        setThoughts(fetchedThoughts);

        if (userDoc.createdAt instanceof Timestamp) {
          const createdAtDate = userDoc.createdAt.toDate();
          setJoinedAt(formatDate(createdAtDate));
        } else {
          setJoinedAt(formatDate(formatFirestoreDate(userDoc.createdAt)));
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userNumber) {
      fetchAndSetData(Number(userNumber));
    }
  }, [fetchAndSetData, userNumber]);

  const contextValue = useMemo(
    () => ({
      userData,
      userId,
      joinedAt,
      thoughts,
      loading,
      fetchAndSetData,
    }),
    [userData, userId, joinedAt, thoughts, loading, fetchAndSetData]
  );

  return (
    <UsersContext.Provider value={contextValue}>
      {children}
    </UsersContext.Provider>
  );
});

export const useUsers = () => {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error("useUsers must be used within a UserProvider");
  }
  return context;
};
